import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>We-make-your.software</h1>
        <br></br>
        <h2>Coming soon</h2>
      </header>
    </div>
  );
}

export default App;
